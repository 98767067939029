import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';

import template from './rux-timeline-region.html';
import css from './rux-timeline-region.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class RuxTimelineRegion extends PolymerElement {
  static get properties() {
    return {
      label: {
        type: String,
      },
      status: {
        type: String,
        reflectToAttribute: true,
        value: 'off',
      },
      scale: {
        type: Number,
        observer: '_updateRegion',
        value: false,
      },
      trackWidth: {
        type: Number,
      },
      filter: {
        type: String,
        observer: '_filterUpdated',
      },
      hidden: {
        type: Boolean,
        value: false,
      },
      duration: {
        type: Number,
      },
      startTime: {
        type: Date,
      },
      endTime: {
        type: Date,
      },
      detail: {
        type: Object,
      },
      selected: {
        type: Boolean,
        value: false,
        reflectToAttribute: true,
        notify: true,
        computed: '_isSelected(selectedRegionLabel, label)',
      },
      temporality: {
        type: String,
        reflectToAttribute: true,
      },
      _startTime: {
        type: Date,
        computed: '_getTime(startTime)',
      },
      _endTime: {
        type: Date,
        computed: '_getTime(endTime)',
      },
      _initialState: {
        type: Object,
        value: false,
      },
    };
  }

  _setDefaultSize() {
    const now = new Date();
    const today = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0);

    const left = ((this._startTime.getTime() - today.getTime()) * this.trackWidth) / this.duration;

    const width = ((this._endTime.getTime() - this._startTime.getTime()) * this.trackWidth) / this.duration;

    // set the initial values for each region
    this._initialState = {
      width: width,
      left: left,
      scale: this.scale,
    };

    this._updateRegion();
  }

  _onWindowResize() {
    this._setDefaultSize();
  }

  _getTime(time) {
    return time;
  }

  _formatTime(time) {
    if (isNaN(time)) return false;

    return new Date(time).toLocaleTimeString(this.locale, {
      hour12: false,
    });
  }

  _getRegionWidth() {
    return this._initialState.width * (this.scale / this._initialState.scale);
  }

  _getRegionLeft() {
    return this._initialState.left * (this.scale / this._initialState.scale);
  }

  _resetSize() {
    this.classList.remove('small', 'standard', 'compact');
  }

  _filterUpdated() {
    if (this.filter.toLowerCase() === 'none') {
      this.hidden = false;
    } else if (this.filter.toLowerCase() !== this.status.toLowerCase()) {
      this.hidden = true;
    } else {
      this.hidden = false;
    }
  }

  _isSelected(selectedLabel, regionLabel) {
    return selectedLabel === regionLabel;
  }

  _updateRegion() {
    const _width = this._getRegionWidth();
    const _left = this._getRegionLeft();

    this.style.left = _left + 'px';
    this.style.width = _width + 'px';

    this._resetSize();
    if (_width > 180) {
      // this is a normal sized widget
      this.classList.add('large');
    } else if (_width < 180 && _width > 140) {
      // this is a mid sized widget without its time element
      this.classList.add('medium');
    } else if (_width < 140 && _width > 40) {
      // this is a small widget without time or label
      this.classList.add('small');
    } else if (_width < 40) {
      // this is a small widget without time or label
      this.classList.add('compact');
    }
  }

  constructor() {
    super();
    this._windowListener = this._onWindowResize.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    this._setDefaultSize();
    window.addEventListener('update', this._windowListener);
    window.addEventListener('resize', this._windowListener);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('update', this._windowListener);
    window.removeEventListener('resize');
  }

  static get template() {
    return html([
      ` <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}
customElements.define('rux-timeline-region', RuxTimelineRegion);
