/* eslint-disable no-unused-vars */
import config from '../config/config.json';
import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

/* Astro Components */
import {RuxClock} from '@astrouxds/rux-clock/rux-clock.js';
import {RuxGlobalStatusBar} from '@astrouxds/rux-global-status-bar/rux-global-status-bar.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxNotification} from '@astrouxds/rux-notification/rux-notification.js';
import {RuxSegmentedButton} from '@astrouxds/rux-segmented-button/rux-segmented-button.js';
import {RuxSlider} from '@astrouxds/rux-slider/rux-slider.js';

/* GRM Services Templates and Styles */

import template from './astro-app.html';
import css from './astro-app.css';
import astroCSS from './astro-css.js';

/*
    GRM Schedule Components & Data
    Add GRM Schedule specific components here
*/
import {AppMenu} from './app-menu.js';
import {StatusIndicators} from './status/status-indicators';
import {DetailPane} from './detail-pane/detail-pane.js';
import {RuxTimeline} from './rux-timeline/rux-timeline.js';
import contactService from './contact-service.js';
const contactData = require('./contact-data.js');
const utils = require('./common-utils.js');

/* eslint-enable no-unused-vars */

/**
 * @polymer
 * @extends HTMLElement
 */
export class AstroApp extends PolymerElement {
  static get properties() {
    return {
      grmSchedule: {
        type: Array,
        value: [],
      },
      currentlySelectedRegion: {
        type: Object,
        value: {},
        observer: '_selectedRegionChanged',
      },
      detailPaneEditMode: {
        type: Boolean,
        value: function() {
          return false;
        },
      },
      detailPaneOpen: {
        type: Boolean,
        value: function() {
          return false;
        },
      },
      statusData: {
        type: Object,
        value: {
          ucaStatus: {progress: 2},
          timestamp: 1546641719633,
        },
      },
    };
  }

  _startStatusIndicatorsWebsocket() {
    setInterval(() => {
      let _progress = this.statusData.ucaStatus.progress;
      const _increment = Math.floor(Math.random() * 5);

      if (_progress + _increment > 100) {
        _progress = _increment;
      } else {
        _progress += _increment;
      }

      const _updatedStatus = {
        ucaStatus: {
          progress: _progress,
        },
      };

      this.set('statusData', _updatedStatus);
    }, 1000);
  }

  _beginNewContactFlow() {
    this.currentlySelectedRegion = {};
    this.toggleDetailPane('open');
    this.detailPaneEditMode = true;
  }

  _selectedRegionChanged() {
    // if a pass is selected while the pane is collapsed
    if (this.currentlySelectedRegion.label && !this.detailPaneOpen) {
      this.toggleDetailPane('open');
      this.detailPaneEditMode = false;

      // if no pass is selected while the pane is collapsed, also on load
    } else if (!this.currentlySelectedRegion.label && !this.detailPaneOpen) {
      this.toggleDetailPane('closed');
      this.detailPaneEditMode = false;

      // if the selected pass is clicked again, it is deselected
    } else if (!this.currentlySelectedRegion.label && this.detailPaneOpen) {
      this.toggleDetailPane('closed');
      this.detailPaneEditMode = true;

      // if the selected pass has changed
    } else if (this.currentlySelectedRegion.label) {
      this.detailPaneEditMode = false;
    } else {
      this.detailPaneEditMode = true;
    }
  }

  toggleDetailPane(force) {
    switch (force) {
      case 'open':
        this.detailPaneOpen = true;
        break;
      case 'closed':
        this.detailPaneOpen = false;
        break;
      default:
        this.detailPaneOpen = !this.detailPaneOpen;
        break;
    }
  }

  displayPopUpMenu(e) {
    if (e.detail.menu) {
      this.appMenuTarget = e.detail.target;
      this.menuItems = e.detail.menu;
    } else {
      this.appMenuTarget = e.currentTarget;
      this.menuItems = config.menuItems;
    }
  }

  displayNotification(e) {
    this.notificationMessage = e.detail.message;
    this.notificationOpen = true;
    window.setTimeout(() => {
      this.notificationOpen = false;
    }, this.notificationTimeout * 1000);
  }

  constructor() {
    super();
    this.detailPaneOpen = false;
    this.menuItems = config.menuItems;
    this.user = config.user;
  }

  /*
   * * @todo this should really be a utility
   */
  _getUTC(time) {
    return new Date(
        time.getUTCFullYear(),
        time.getUTCMonth(),
        time.getUTCDate(),
        time.getUTCHours(),
        time.getUTCMinutes(),
        time.getUTCSeconds()
    );
  }

  connectedCallback() {
    super.connectedCallback();
    this._startStatusIndicatorsWebsocket();

    const timelineData = contactService();

    /*
      So in reality it would probably be better for the server
      to parse out useless data before sending it to the client,
      but we’ll fake it here and reject any pass slot that occurs
      before the current time of day and hasn’t already been
      reserved
    */
    timelineData.forEach((track) => {
      // now
      const now = this._getUTC(new Date());

      return track.antennae.forEach((antennae) => {
        const filteredPassSlots = antennae.passSlots.filter((pass) => pass.reserved || pass.startTime > now);
        antennae.passSlots = filteredPassSlots;
      });
    });

    this.grmSchedule = timelineData;

    this.addEventListener('createNewContact', this._beginNewContactFlow);
    this.addEventListener('AddCreatedContact', this.displayNotification);
    this.addEventListener('closeDetailPane', () => this.toggleDetailPane('closed'));
    this.addEventListener('showPopUpMenu', this.displayPopUpMenu);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('createNewContact', this._beginNewContactFlow);
    this.removeEventListener('AddCreatedContact', this.displayNotification);
    this.removeEventListener('closeDetailPane', () => this.toggleDetailPane('closed'));
    this.removeEventListener('showPopUpMenu', this.displayPopUpMenu);
  }

  ready() {
    super.ready();
    this.notificationMessage = '';
    this.notificationOpen = false;
    this.notificationTimeout = 8;
    this.detailPaneEditMode = true;
  }
  static get template() {
    return html([
      ` <style include="astro-css">
        ${css}
        </style> 
        ${template}`,
    ]);
  }
}
customElements.define('astro-app', AstroApp);
