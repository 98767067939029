module.exports = {
  is(a, b) {
    return a === b;
  },

  isNull(a) {
    return a === null;
  },

  isPlural(int) {
    return int === 1 ? '' : 's';
  },

  capitalize(val) {
    if (!val) return;
    return val.charAt(0).toUpperCase() + val.substring(1);
  },

  countKeys(object) {
    return Object.keys(object).length;
  },

  formatMachineTime(timestamp) {
    // assumes timestamp is a UTC Epoch
    const time = new Date(timestamp);

    return `
      ${time.getUTCFullYear()}-${time.getUTCMonth()}-${time.getUTCDate()} 
      ${time.getUTCHours()}:${time.getUTCMinutes()}:${time.getUTCSeconds()}:${time.getUTCMilliseconds()}
    `;
  },

  formatReadableTime(timestamp ) {
    // assumes timestamp is a UTC Epoch
    const time = new Date(timestamp);

    return new Date(time).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  },

  formatDayOfYear(timestamp) {
    const time = new Date(timestamp);

    function zeroFill(number, width) {
      width -= number.toString().length;
      if (width > 0) {
        return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
      }
      return number + ''; // always return a string
    }

    const doy =
      (Date.UTC(time.getFullYear(), time.getMonth(), time.getDate()) - Date.UTC(time.getFullYear(), 0, 0)) /
      24 /
      60 /
      60 /
      1000;
    return zeroFill(doy, 3);
  },

  mapAutomationMode(int) {
    switch (int) {
      case 1:
        return 'Semi-Automation';
      case 2:
        return 'Manual';
      default:
        return 'Full Automation';
    }
  },
  mapPriority(int) {
    switch (int) {
      case 1:
        return 'Medium';
      case 2:
        return 'High';
      default:
        return 'Low';
    }
  },
  mapStatuses(status) {
    switch (status) {
      case 'off':
        return 'inactive';
      case 'caution':
        return 'executing';
      case 'critical':
        return 'failed';
      case 'normal':
        return 'complete';
      default:
        return 'standby';
    }
  },
};
