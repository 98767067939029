import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import {afterNextRender} from '@polymer/polymer/lib/utils/render-status.js';
import {} from '@polymer/polymer/lib/elements/dom-repeat.js';
/* eslint-disable no-unused-vars */
import {RuxTimelineTrack} from './rux-timeline-track.js';
/* eslint-enable no-unused-vars */

import template from './rux-timeline.html';
import css from './rux-timeline.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class RuxTimeline extends PolymerElement {
  static get properties() {
    return {
      label: {
        type: String,
        value: 'Timeline',
      },
      duration: {
        type: Number,
        value: 86400000,
      },
      filters: {
        type: Array,
        value: [
          {label: 'All', value: 'none'},
          {label: 'Upcoming', value: 'standby'},
          {label: 'Executing', value: 'caution'},
          {label: 'Complete', value: 'normal'},
          {label: 'Failed', value: 'critical'},
        ],
      },
      startTime: {
        type: Date,
        value: false,
      },
      status: {
        type: String,
        value: 'null',
      },
      tracks: {
        type: Array,
      },
      playbackControls: {
        type: String,
        value: false,
      },
      zoomControl: {
        type: Boolean,
        value: false,
      },
      playheadControl: {
        type: Boolean,
        value: false,
      },
      selected: {
        type: Object,
        notify: true,
      },
      initialScale: {
        type: Number,
      },
      _scale: {
        type: Number,
        observer: '_updateTimelineScale',
      },
      timezone: {
        type: String,
        value: 'utc',
      },
      selectedRegion: {
        type: Object,
        notify: true,
        observer: '_selectedRegionChanged',
      },
    };
  }

  _selectedRegionChanged() {}

  _onClick(e) {
    /*
     TODO: Needs a refactor, probably use the concept from tabs/tab panels
     of registering a track to its track label instead of this song and dance
    */

    const _label = e.currentTarget;
    const _track = this.shadowRoot.querySelector(`[aria-labelledby="label-${_label.getAttribute('index')}"]`);

    _track.selected = !_track.selected;
    if (_track.selected) {
      _label.setAttribute('selected', '');
    } else {
      _label.removeAttribute('selected');
    }
  }

  _dispatchPlayheadEvent(time) {
    // TODO: use a while loop to have a more efficient sub-shadowDom inquisition
    const _t = this.shadowRoot.querySelectorAll('rux-timeline-track');
    _t.forEach((track) => {
      const _r = track.shadowRoot.querySelectorAll('rux-timeline-region');

      _r.forEach((region) => {
        // handles setting a timeline region to a past state
        if (time > region.startTime && time < region.endTime) {
          region.temporality = 'present';
        } else if (time < region.startTime) {
          region.temporality = 'future';
        } else if (time > region.endTime) {
          region.temporality = 'past';
        }
      });
    });
  }

  _updateCurrentTime(time) {
    const now = new Date();
    const utc = new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
    );
    const then = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0);

    // time of today, like right now
    const dif = utc.getTime() - then.getTime();
    const loc = (dif * this._ruler.offsetWidth) / this.duration;

    if (loc > this._ruler.offsetWidth) {
      this._currentTime.style.display = 'none';
    } else {
      this._currentTime.style.display = 'block';
      this._currentTime.style.left = loc + 'px';

      // violates DRY, look for a way to bind maybe _currentTime instead
      this._loc = loc + 'px';
      this.currentTimeAnimator = window.requestAnimationFrame(this._updateCurrentTime.bind(this));
    }

    // throttle dispatching events to regions
    if (!this._last || time - this._last >= 1 * 5000) {
      this._last = time;
      this._dispatchPlayheadEvent(utc);
    }
  }

  _updateTimelineScale() {
    const scale = `${Number(this._scale)}%`;
    this._tracks.style.width = scale;
    this._track.style.width = scale;

    window.dispatchEvent(new CustomEvent('update'));
  }

  /*
    TODO: Consider moving the ruler to its own component entirely and moving
    all the tic/label logic there
  */
  _setTics() {
    // TODO: Allow for a start time that isn’t midnight of the current day
    const start = 0;

    // loop through the duration set for the timeline
    for (let i = start; i < this._durationHours; i++) {
      // create a new tic
      const tic = document.createElement('div');

      // generate a readable time label
      tic.innerHTML = `${i.toString().padStart(2, '0')}:00`;

      // append to child
      this._ruler.appendChild(tic);
    }
  }

  _scroll(e) {
    if (e.altKey) {
      let _delta = (this._scale += e.deltaY / 10);

      if (_delta < this._minScale) {
        _delta = this._minScale;
      } else if (_delta > this._maxScale) {
        _delta = this._maxScale;
      }

      this._scale = Math.floor(_delta);
    } else {
      e.currentTarget.scrollLeft += Math.floor(e.deltaY);
    }
  }

  notifyParentToCreateContact(e) {
    this.dispatchEvent(
        new CustomEvent('createNewContact', {
          bubbles: true,
          composed: true,
        })
    );
    e.stopPropagation();
    return false;
  }

  constructor() {
    super();

    // set a default scale if one doesn’t exist
    if (!this.initialScale) this.initialScale = 100;

    // bind scroll listener to scroll event
    this._scrollListener = this._scroll.bind(this);

    // debounce for request animation frame
    this._last = 0;

    afterNextRender(this, () => {
      // if the browser supports the resizeObserver (currently Chrome 55+)
      // use that to handle both window and css transitions of width. This
      // provides a much smoother resize
      if ('ResizeObserver' in window) {
        this.resizeObserver = new ResizeObserver(() => {
          this._updateTimelineScale();
        });
        this.resizeObserver.observe(this);

        // otherwise use a mutation observer to handle resizing via CSS/JS
        // and a window event listener to handle window resizing.
      } else {
        const _track = this.shadowRoot.getElementById('rux-timeline__viewport__track-container');

        this.mutationObserver = new MutationObserver(() => {
          this._updateTimelineScale();
        });

        this.mutationObserver.observe(_track, {
          attributes: true,
          childList: false,
          subtree: true,
        });
      }
    });
  }

  connectedCallback() {
    super.connectedCallback();

    // hard coded min/max scale (for now)
    this._minScale = 100;
    this._maxScale = 500;

    // get the current time indicator
    this._currentTime = this.shadowRoot.getElementById('rux-timeline__current-time');

    // get the track container; this is the larger container for
    // tracks, ruler, playhead and current time indicator
    this._track = this.shadowRoot.getElementById('rux-timeline__viewport__track-container');
    this._track.addEventListener('wheel', this._scrollListener, {
      passive: true,
    });

    // get the timeline ruler
    this._ruler = this.shadowRoot.getElementById('rux-timeline__ruler');

    // get the tracks container; this differs from the track_container
    // in that it only contains the tracks.
    this._tracks = this.shadowRoot.getElementById('rux-timeline__viewport__tracks');

    // if duration is less than 1000 then assume the data was in hours.
    // NOTE: a future enhancement would be to allow for some form of passing
    // time in e.g. 12h for 12 hours
    if (this.duration < 1000) {
      this.duration = this.duration * 60 * 60 * 1000;
    }

    this._durationHours = this.duration / 1000 / 60 / 60;

    this._scale = this.initialScale;

    this._setTics();

    this.currentTimeAnimator = window.requestAnimationFrame(this._updateCurrentTime.bind(this));
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this._track.removeEventListener('wheel', this._scrollListener);

    window.cancelAnimationFrame(this.currentTimeAnimator);
    this.mutationObserver.disconnect();
  }

  static get template() {
    return html([
      ` <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}
customElements.define('rux-timeline', RuxTimeline);
