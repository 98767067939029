import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
/* eslint-disable no-unused-vars */
import {RuxButton} from '@astrouxds/rux-button/rux-button.js';
/* eslint-enable no-unused-vars */
const utils = require('../common-utils.js');

import template from './detail-pane.html';
import css from './detail-pane.css';

const contactData = require('../contact-data.js');

/**
 * @polymer
 * @extends HTMLElement
 */
export class DetailPane extends PolymerElement {
  // Properties in HTML should be hyphenated, when referenced
  // in Javascript use camelCase and remove the hyphen
  static get properties() {
    return {
      open: {
        type: Boolean,
        reflectToAttribute: true,
        value: function() {
          return false;
        },
      },
      passDetail: {
        type: Object,
        value: function() {
          return {};
        },
      },
      detailAvailable: {
        type: Boolean,
        computed: 'isValidDetailObject(passDetail)',
        reflectToAttribute: true,
        value: function() {
          return false;
        },
      },
      editMode: {
        type: Boolean,
        notify: true,
        value: false,
      },
      currentTime: {
        type: Date,
        value: function() {
          return new Date();
        },
      },
      schedule: {
        type: Array,
      },
      currentFilters: {
        type: Object,
        notify: true,
        value: function() {
          return {
            reserved: false,
          };
        },
      },
      draftReservationDetail: {
        type: Object,
        value: function() {
          return {
            priority: 0,
            autoMode: 0,
            equipmentConfig: 0,
          };
        },
      },
      availablePasses: {
        type: Array,
        computed: 'filterPasses(currentFilters.*, schedule.*)',
      },
    };
  }

  resetPane() {
    this.set('draftContact', null);
    this.set('selectedLabel', null);
    this.set('draftReservationDetail.priority', 0);
    this.set('draftReservationDetail.autoMode', 0);
    this.set('draftReservationDetail.equipmentConfig', 0);
    this.set('currentFilters.iron', 'any');
    this.set('currentFilters.groundStation', 'any');
  }

  closePane() {
    this.resetPane();
    this.dispatchEvent(
        new CustomEvent('closeDetailPane', {
          bubbles: true,
          composed: true,
        })
    );
    return false;
  }

  flattenPasses() {
    let allPasses = [];
    this.schedule.forEach((groundStation) => {
      groundStation.antennae.forEach((antenna) => {
        const thesePasses = antenna.passSlots;
        allPasses = [...allPasses, ...thesePasses];
      });
    });
    return allPasses;
  }

  filterPasses() {
    function removeUnsetFilters(filters) {
      for (const key in filters) {
        if (filters[key] === 'any') delete filters[key];
      }
      return filters;
    }
    let tempFilters = Object.assign({}, this.currentFilters);
    tempFilters = removeUnsetFilters(tempFilters);

    const passes = this.flattenPasses();
    const filteredPasses = passes.filter(function(pass) {
      return Object.keys(this).every((key) => pass[key] === this[key]);
    }, tempFilters);

    if (
      this.detailAvailable &&
      this.passDetail.iron === this.currentFilters.iron &&
      this.passDetail.groundStation === this.currentFilters.groundStation
    ) {
      filteredPasses.unshift(this.passDetail);
      this.selectedLabel = this.passDetail.label;
      this.draftContact = this.passDetail;
    } else {
      this.draftContact = null;
      this.selectedLabel = null;
    }
    return filteredPasses;
  }

  selectPass(e) {
    this.draftContact = e.model.pass;
    this.selectedLabel = e.model.pass.label;
  }

  saveContact(e) {
    if (!this.draftContact) {
      // Cannot Save New Contact; no pass has been selected
      return false;
    }

    let message = `Saved changes to contact ${this.draftContact.label}.`;

    if (!this.detailAvailable) {
      message = `Added contact ${this.draftContact.label}.`;
    }

    this.draftContact.detail = this.draftReservationDetail;
    this.draftContact.detail.equipmentConfig = this.equipmentConfigs[this.draftReservationDetail.equipmentConfig];
    this.draftContact.reserved = true;
    this.dispatchEvent(
        new CustomEvent('AddCreatedContact', {
          detail: {
            newContact: this.draftContact,
            message: message,
          },
          bubbles: true,
          composed: true,
        })
    );
    this.closePane();
    e.stopPropagation();
    return false;
  }

  editContact() {
    this.currentFilters = {
      groundStation: this.passDetail.groundStation,
      iron: this.passDetail.iron,
      reserved: false,
    };

    this.draftReservationDetail = {
      autoMode: this.passDetail.detail.autoMode,
      equipmentConfig: this.getIndexOfEquipmentStringByLabel(this.passDetail.detail.equipmentConfig.label),
      priority: this.passDetail.detail.priority,
    };

    this.editMode = true;
  }

  getEquipmentStringByIndex(index) {
    return this.equipmentConfigs[index].string;
  }

  getIndexOfEquipmentStringByLabel(label) {
    return this.equipmentConfigs.findIndex((obj) => obj.label == label);
  }

  multipleFiltersSet(filters) {
    return this.countKeys(filters) > 1 ? true : false;
  }

  sortPassesByAOS(a, b) {
    return a.startTime < b.startTime ? -1 : 1;
  }

  isValidDetailObject(detail) {
    return detail.hasOwnProperty('label');
  }

  constructor() {
    super();

    /* Register utility methods */
    this.is = utils.is;
    this.isNull = utils.isNull;
    this.countKeys = utils.countKeys;
    this.formatMachineTime = utils.formatMachineTime;
    this.formatReadableTime = utils.formatReadableTime;
    this.formatDayOfYear = utils.formatDayOfYear;
    this.mapAutomationMode = utils.mapAutomationMode;
    this.mapPriority = utils.mapPriority;
    this.mapStatuses = utils.mapStatuses;

    /* set data defaults */
    this.potentialIRONValues = contactData.spacecraft;
    this.groundStations = contactData.groundStations;
    this.equipmentConfigs = contactData.commonEquipmentConfigs;
    this.priorities = [0, 1, 2];
    this.modes = [0, 1, 2];

    this.draftContact = null;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('createNewContact', () => {
      this.resetPane();
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  static get template() {
    return html([
      ` <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('detail-pane', DetailPane);
