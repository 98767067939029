const contactData = require('./contact-data.js');

function getRandomEntryFrom(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

function getToday() {
  const today = new Date();
  const start = new Date(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      0,
      0,
      0
  );

  const end = new Date(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      start.getHours() + 24,
      0,
      0
  );

  const now = new Date(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      today.getUTCHours(),
      today.getUTCMinutes(),
      today.getUTCSeconds()
  );

  return {
    start: start,
    end: end,
    now: now,
  };
}

function decideStatus(start, end) {
  const today = getToday();

  // assign a default status of upcoming
  let status = 'standby';

  if (
    today.now.getTime() > start.getTime() &&
    today.now.getTime() < end.getTime()
  ) {
    // this pass is occuring now, it can either be executing or inactive
    status = Math.random() > 0.75 ? 'caution' : 'off';
  } else if (today.now.getTime() > start.getTime()) {
    // otherwise this pass has already occured, it failed or is complete
    status = Math.random() > 0.85 ? 'critical' : 'normal';
  }
  return status;
}

function generateScheduledPasses(start, end) {
  return {
    equipmentConfig: getRandomEntryFrom(contactData.commonEquipmentConfigs),
    status: decideStatus(start, end),
    autoMode:
      Math.random() > 0.5 // 50% will be 0 — fully automated (default)
        ? 0
        : Math.random() > 0.5 // 25% will be 1 or 2
        ? 1 // 1  = semi-automated
        : 2, // 2 = manual
    priority:
      Math.random() > 0.5 // 50% will be 0 — Low (default)
        ? 0
        : Math.random() > 0.3 // 35% will be Medium, 15% will be High
        ? 1 // 1  = Medium
        : 2, // 2 = High
  };
}

function generatePassSlots(antenna, station) {
  const passSlots = [];
  const today = getToday();
  let nextStart = today.start;
  const lastSlot = today.end;
  const maxConsecutivePasses = 5;
  let consecutivePasses = 6; // force initial condition to run
  let spacecraft = {};

  do {
    const isReserved = Math.random() > 0.75 ? true : false; // 25% of windows will be reserved
    const duration = Math.floor(Math.random() * 30) + 15; // 15-45 minute pass durations
    const nextEnd = new Date(nextStart);
    nextEnd.setHours(nextStart.getHours(), nextStart.getMinutes() + duration);

    // use same spacecraft for 5 sequential passes for more realistic scheduling
    if (consecutivePasses >= maxConsecutivePasses) {
      consecutivePasses = 0;
      spacecraft = getRandomEntryFrom(contactData.spacecraft);
    }
    const integerRev =
      spacecraft.revPrefix +
      nextStart
          .getTime()
          .toString()
          .substr(3, 3); // making up a revolution number dependent on time
    const fractionalRev = nextStart
        .getTime()
        .toString()
        .substr(6, 2);
    const rev = `${integerRev}.${fractionalRev}`;

    passSlots.push({
      status: decideStatus(nextStart, nextEnd),
      startTime: nextStart,
      endTime: nextEnd,
      reserved: isReserved,
      label: `${spacecraft.iron}-${station.callsign}-${rev}`,
      iron: spacecraft.iron, // 5-digit unique number, assigned to each satellite (Inter-Range Operating Number)
      rev: rev,
      durationMins: duration,
      antenna: antenna,
      groundStation: station.callsign,
      detail: isReserved
        ? generateScheduledPasses(nextStart, nextEnd)
        : {},
    });
    nextStart = nextEnd;
    consecutivePasses++;
  } while (nextStart < lastSlot);

  return passSlots;
}

function generateAntennae(quant, station) {
  const antennae = [];
  for (let i = 0; i < quant; i++) {
    const antennaName = `${station.callsign}-${[i + 1]}`;

    antennae.push({
      label: antennaName,
      passSlots: generatePassSlots(antennaName, station),
    });
  }
  return antennae;
}

function generateGroundStations(stationList) {
  const stations = [];
  for (let i = 0; i < stationList.length; i++) {
    stations.push({
      label: `${stationList[i].name} (${stationList[i].callsign})`,
      antennae: generateAntennae(
          Math.floor(Math.random() * 4) + 1, // between 1-4 antennae per ground station
          stationList[i]
      ),
    });
  }

  return stations;
}

export default function() {
  const tracks = generateGroundStations(contactData.groundStations);
  return tracks;
}
